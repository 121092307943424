import React from 'react';
import './App.scss';
import HomePage from './page/home/index'

function App() {
    return (
        <>
            <HomePage />
        </>
    );
}

export default App;
